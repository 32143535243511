@import '../../../base/bootstrap-extended/include';
@import '../../../base/components/include';

.dropzone {
  display: flex;
  cursor: pointer;
  min-height: 300px;
  align-items: 'center';
  justify-content: center;
  background-color: $body-bg;
  border: 1px dashed $primary;
  border-radius: $border-radius;
}

.dark-layout {
  .dropzone {
    background-color: $theme-dark-body-bg;
  }
  .uppy-Container {
    background-color: $theme-dark-body-bg;
    .uppy-Root {
      background-color: $theme-dark-body-bg;
      button {
        border-color: $theme-dark-border-color;
        background-color: $theme-dark-body-bg;
      }
    }
  }
}

.uppy-Root {
  border-radius: $border-radius;
  border-color: $primary;

  &.uppy-DragDrop-container:focus {
    box-shadow: none;
  }

  .uppy-DragDrop-arrow {
    fill: $primary;
  }

  .uppy-DragDrop-label {
    color: $body-color;
  }

  .uppy-DragDrop-browse {
    color: $primary;
  }
}
